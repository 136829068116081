<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Sensores - Asignación Tanque / Bebedero</h4>
            <div class="small text-muted">Asigne un tanque o bebedero vinculado con un sensor para un cliente</div>
          </b-col>         
        </b-row>
      </b-card>
      
      <b-card header-tag="header" footer-tag="footer">

        <!-- TIPO -->
        <b-row>
          <b-col md="12">
            <b-form-group label="Tipo de Asignación">
              <b-form-radio-group
                v-model="crud.form.type_asignation"
                :options="optTypeAsignation"                
              ></b-form-radio-group>
            </b-form-group>            
          </b-col>          
        </b-row>

        <!-- DIVISION -->
        <b-row>
          <b-col>
            <hr>
          </b-col>
        </b-row>
        
        <b-row>          
          <!-- CLIENTE -->
          <b-col md="6">
            <b-row>
              <b-col md="12">
                <FindObject render="search"
                            type="customers" 
                            @select-object="loadCustomer($event)" 
                            :where="conditionCustomersActive" />
              </b-col>
            </b-row>
          </b-col>

          <!-- TANQUE -->
          <b-col md="6" v-if="crud.form.type_asignation == 'tanque'">
            <b-row>
              <b-col md="12">
                <FindObject type="tanks" 
                            @select-object="loadTank($event)"/>            
              </b-col>        
            </b-row>
            <b-row v-if="crud.form.tank">
              <b-col md="2" v-if="this.crud.form.tank.image">
                <b-img :src="crud.form.tank.image" thumbnail fluid class="w-100"/>
              </b-col>
              <b-col md="2" v-else class="crud-associate-thumbnail-custom">
                <b-icon icon="image" class="h1" v-b-tooltip.hover title="Imagen No Disponible" />
              </b-col>                
              <b-col md="6">
                <b>Descripción:</b><br>
                {{this.crud.form.tank.description}} <br>                                
                <b-badge variant="success" v-if="this.crud.form.tank.own">Tanque Propio</b-badge>
                <b-badge variant="danger" v-else>Tanque de Tercero</b-badge>
              </b-col>                        
              <b-col md="4">
                <b>Código:</b> {{this.crud.form.tank.code}}<br>
                <b>Diametro:</b> {{this.crud.form.tank.diameter}} cm<br>
                <b>Alto:</b> {{this.crud.form.tank.height}} cm<br>
                <b>Capacidad:</b> {{this.crud.form.tank.capacity}} lt<br>
              </b-col>                                      
            </b-row>
          </b-col>
        </b-row>

        <!-- CONFIGURACION TANQUE -->
        <b-row class="mt-3" v-if="crud.form.type_asignation == 'tanque'">                    
          <b-col md="6">            
            <b-card
              header="Datos del Sensor"
              border-variant="dark"              
              header-bg-variant="secondary"
              header-text-variant="black">              

              <b-row>
                <b-col md="6">
                  <b-card-text>
                    <b-form-group label="Nodo" description="Ingresar el Identificador del Nodo">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.form.sensor_code">
                      </b-form-input>
                    </b-form-group>                
                  </b-card-text>
                </b-col>
                <b-col md="6">
                  <b-card-text>
                      <b-form-group label="Sensor" description="Ingresar sensor asignado al tanque">
                        <b-form-select v-model="crud.form.sensor_val" :options="arr.select.sensors" size="sm"></b-form-select>                    
                      </b-form-group>
                  </b-card-text>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">                
                  <b-card-text>
                    <b-form-group label="Alerta" description="Porcentaje mínimo para disparar alertas">
                      <b-form-input type="number"                                    
                                    size="sm"
                                    v-model="crud.form.alert_min">
                      </b-form-input>
                    </b-form-group>                
                  </b-card-text>              
                </b-col>
                <b-col md="6">                
                  <b-card-text>
                    <b-form-group label="Última Alerta" description="Último porcentaje mínimo para disparar alertas">
                      <b-form-input type="number"                                    
                                    size="sm"
                                    v-model="crud.form.alert_min_last">
                      </b-form-input>
                    </b-form-group>                
                  </b-card-text>              
                </b-col>                
              </b-row>

              <b-row>
                <b-col md="6">                
                  <b-card-text>
                    <b-form-group label="Batería Volt" description="Volt de la batería">
                      <b-form-input type="number"                                    
                                    size="sm"                  
                                    disabled
                                    v-model="crud.form.batery_volt">
                      </b-form-input>
                    </b-form-group>                
                  </b-card-text>              
                </b-col>
                <b-col md="6">                
                  <b-card-text>
                    <b-form-group label="Batería Alerta" description="Volt mínimo para alertas">
                      <b-form-input type="number"                                    
                                    size="sm"
                                    disabled
                                    v-model="crud.form.batery_alert">
                      </b-form-input>
                    </b-form-group>                
                  </b-card-text>              
                </b-col>                
              </b-row>              
            </b-card>
          </b-col>   
          <b-col md="6">            
            <b-card
              header="Información Complementaria"
              border-variant="dark"              
              header-bg-variant="secondary"
              header-text-variant="black">  
              
              <b-card-text>
                <b-row>                   
                  <b-col md="6">
                    <b-form-group label="Alias" description="Ingrese un alias para el tanque">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.alias">
                      </b-form-input>
                    </b-form-group>
                  </b-col>       
                  <b-col md="6">
                    <b-form-group label="Líquido de Abastecido" description="Ingrese el líquido de abastecido para el tanque">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.type">
                      </b-form-input>
                    </b-form-group>
                  </b-col>    
                  <b-col md="6">
                    <b-form-group label="Número de Serie" description="Ingrese el número de serie del tanque">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.serial_number">
                      </b-form-input>
                    </b-form-group>
                  </b-col>       
                  <b-col md="6">
                    <b-form-group label="Fecha de Vencimiento" description="Ingrese la fecha de vencimiento para este tanque">
                      <b-form-datepicker size="sm" v-model="crud.form.expiration" placeholder="Fecha de Vencimiento" local="es"></b-form-datepicker>                      
                    </b-form-group>
                  </b-col>                      
                </b-row>
              </b-card-text>
            </b-card>
          </b-col>                                        
        </b-row>

        <!-- CONFIGURACION BEBEDERO -->
        <b-row class="mt-3" v-if="crud.form.type_asignation == 'bebedero'">          
          <b-col md="12">            
            <b-card
              header="Datos del Bebedero"
              border-variant="dark"              
              header-bg-variant="secondary"
              header-text-variant="black">              

              <b-row>
                <b-col md="6">
                  <b-form-group label="Alias" description="Ingrese un alias para el bebedero">
                    <b-form-input type="text"
                                  size="sm"
                                  v-model="crud.form.alias_trough">
                    </b-form-input>
                  </b-form-group>
                </b-col>       
                <b-col md="6">
                  <b-card-text>
                    <b-form-group label="Nodo" description="Ingresar el Identificador del Nodo">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.form.sensor_code">
                      </b-form-input>
                    </b-form-group>                
                  </b-card-text>
                </b-col>                      
              </b-row>              
            </b-card>
          </b-col>                                     
        </b-row>

      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>            
            <b-button variant="dark" size="md" class=" pull-right" @click="openConfirm()">Guardar</b-button>
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>  
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'  
  import FindObject from '@/components/inc/find/findObject'

  export default {
    components: {
      FindObject
    },
    data: () => {
      return {
        access: {
          module_id: Modules.SENSORES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudSensorsAssociateTankCustomer',
          elements: {}
        },
        crud: {
          form: {
            customer_id: 0,
            customer: null,
            tank_id: 0,  
            tank: null,          
            sensor_code: 0,
            sensor_val: '',
            type: '',
            serial_number: '',
            expiration: '',
            alert_min: 0,
            alert_min_last: 0,
            alias: '',
            batery_volt: 3.6,
            batery_alert: 3.2,
            capacity: 0,   
            alias_trough: '',
            type_asignation: 'tanque'
          },                    
        }, 
        arr: {
          select: { 
            sensors: [
              { value: '1', text: 'Sensor 1' },
              { value: '2', text: 'Sensor 2' },                            
            ],          
          }                
        },
        optTypeAsignation: [
          { text: 'Tanque', value: 'tanque' },
          { text: 'Bebedero', value: 'bebedero' },
        ]          
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },
    computed: {
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      }
    },   
    methods: {      
      loadCustomer (object) {
        if(object){
          this.crud.form.customer = object
          this.crud.form.customer_id = object.id
        } else {
          this.crud.form.customer = null
          this.crud.form.customer_id = 0
        }
      },
      loadTank (object) {
        if(object){
          this.crud.form.tank = object
          this.crud.form.tank_id = object.id
        } else {
          this.crud.form.tank = null
          this.crud.form.tank_id = 0
        }
      },
      openConfirm() {
        if(!this.crud.form.customer) {
          this.$awn.alert("No se cargó el cliente");
          return false
        }

        if(this.crud.form.type_asignation=='tanque' && !this.crud.form.tank) {
          this.$awn.alert("No se cargó el tanque");
          return false
        }

        if(this.crud.form.type_asignation=='bebedero' && !this.crud.form.alias_trough) {
          this.$awn.alert("No se cargó el alias del bebedero");
          return false
        }        
        
        var question = ''
        var title = ''
        if(this.crud.form.type_asignation=='tanque') {
          question = '¿Confirmar asignación del tanque [' + this.crud.form.tank.name.toUpperCase() + '] al cliente [' + this.crud.form.customer.name.toUpperCase() + ']?'
          title = 'Asignación de Tanque'
        }

        if(this.crud.form.type_asignation=='bebedero') {
          question = '¿Confirmar asignación del bebedero [' + this.crud.form.alias_trough.toUpperCase() + '] al cliente [' + this.crud.form.customer.name.toUpperCase() + ']?'
          title = 'Asignación de Tanque'
        }        

        this.$bvModal.msgBoxConfirm(question, {
          title: title,
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'CONFIRMAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            this.save()
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },  
      save() {        
        let loader = this.$loading.show();       
        
        if(this.crud.form.tank) {
          this.crud.form.capacity = this.crud.form.tank.capacity
        }        

        var result = serviceAPI.asignarTanque(this.crud.form);
        
        result.then((response) => {          
          var data = response.data
          loader.hide()          
          this.$awn.success("Tanque asignado con éxito");
          
          this.$router.push({ name: 'SensorsStaffDetailSensorsTankCustomer', params: { customerTankID: data.id } })                              
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        }) 
      }
    }
  }
</script>
<style>
  .crud-associate-thumbnail-custom{
    text-align: center;
    margin-top: 15px;
  }
  .crud-associate-thumbnail-custom svg {
    padding: 0.25rem;
    background-color:
    #e4e5e6;
    border: 1px solid
    #c8ced3;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;    
  }
</style>>